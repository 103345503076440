import React from "react";
import { Link } from 'react-router-dom';


export default function AvBar() {
  return (
    <>
      <nav>
        <div className="container-fluid bg-dark py-2 d-none d-md-flex">
          <div className="container">
            <div className="d-flex justify-content-between topbar">
              <div className="top-info">
                <small className="me-3 text-white-50">
                  <Link to="#"><i className="fas fa-map-marker-alt me-2 text-secondary"></i></Link>
                  A/118 Sector 63 Noida
                </small>
                <small className="me-3 text-white-50">
                  <Link to="#"><i className="fas fa-envelope me-2 text-secondary"></i></Link>
                  Sales@intrilogy.in
                </small>
              </div>
              <div id="note" className="text-secondary d-none d-xl-flex">
                <small>Note: We help you to Grow your Business</small>
              </div>
              <div className="top-link">
                <Link to="#" className="bg-light nav-fill btn btn-sm-square rounded-circle">
                  <i className="fab fa-facebook-f text-primary"></i>
                </Link>
                <Link to="#" className="bg-light nav-fill btn btn-sm-square rounded-circle">
                  <i className="fab fa-twitter text-primary"></i>
                </Link>
                <Link to="#" className="bg-light nav-fill btn btn-sm-square rounded-circle">
                  <i className="fab fa-instagram text-primary"></i>
                </Link>
                <Link to="#" className="bg-light nav-fill btn btn-sm-square rounded-circle me-0">
                  <i className="fab fa-linkedin-in text-primary"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-primary">
          <div className="container">
          <nav className="navbar navbar-dark navbar-expand-lg py-0">
      <Link to="/" className="navbar-brand">
      <img src="img/TECH-01.png" width="150" height="50" />
      </Link>
      <button
        type="button"
        className="navbar-toggler me-0"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse bg-transparent" id="navbarCollapse">
        <div className="navbar-nav ms-auto mx-xl-auto p-0">
          <Link to="/" className="nav-item nav-link active text-secondary">
            Home
          </Link>
          <Link to="/aboutus" className="nav-item nav-link">
            About
          </Link>
          <Link to="/services" className="nav-item nav-link">
            Services
          </Link>
          <Link to="/projects" className="nav-item nav-link">
            Projects
          </Link>
          {/* <div className="nav-item dropdown">
            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
              Pages
            </Link>
            <div className="dropdown-menu rounded">
              <Link to="/blog" className="dropdown-item">
                Our Blog
              </Link>
              <Link to="/team" className="dropdown-item">
                Our Team
              </Link>
              <Link to="/testimonial" className="dropdown-item">
                Testimonial
              </Link>
              <Link to="/404" className="dropdown-item">
                404 Page
              </Link>
            </div>
          </div> */}
          <Link to="/contact" className="nav-item nav-link">
            Contact
          </Link>
        </div>
      </div>
      <div className="d-none d-xl-flex flex-shrink-0">
        <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
          <Link to="#" className="position-relative animated tada infinite">
            <i className="fa fa-phone-alt text-white fa-2x"></i>
            <div className="position-absolute" style={{ top: '-7px', left: '20px' }}>
              <span><i className="fa fa-comment-dots text-secondary"></i></span>
            </div>
          </Link>
        </div>
        <div className="d-flex flex-column pe-4 border-end">
          <span className="text-white-50">Have any questions?</span>
          <span className="text-secondary">Call: +91 7835968088</span>
        </div>
        <div className="d-flex align-items-center justify-content-center ms-4">
          <Link to="#"><i className="bi bi-search text-white fa-2x"></i></Link>
        </div>
      </div>
    </nav>
          </div>
        </div>
      </nav>
    </>
  );
}
