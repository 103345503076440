import React from "react";

export default function Projects() {
    return (
        <>
           <div className="container-fluid project py-5 mb-5">
  <div className="container">
    <div
      className="text-center mx-auto pb-5 wow fadeIn"
      data-wow-delay=".3s"
      style={{ maxWidth: 600 }}
    >
      <h5 className="text-primary">Our Project</h5>
      <h1>Our Recently Completed Projects</h1>
    </div>
    <div className="row g-5">
      <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
        <div className="project-item">
          <div className="project-img">
            <img
              src="img/Project/astrology.jpg"
              className="img-fluid w-100 rounded"
              alt=""
            />
            <div className="project-content">
              <a href="#" className="text-center">
                <h4 className="text-secondary">Astrology</h4>
                <p className="m-0 text-white">Astrology</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
        <div className="project-item">
          <div className="project-img">
            <img
              src="img/Project/reatils.jpg"
              className="img-fluid w-100 rounded"
              alt=""
            />
            <div className="project-content">
              <a href="#" className="text-center">
                <h4 className="text-secondary">Retail & Ecommerce</h4>
                <p className="m-0 text-white">Retail & Ecommerce</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
        <div className="project-item">
          <div className="project-img">
            <img
              src="img/Project/gaming-apps.jpg"
              className="img-fluid w-100 rounded"
              alt=""
            />
            <div className="project-content">
              <a href="#" className="text-center">
                <h4 className="text-secondary">Gaming Apps</h4>
                <p className="m-0 text-white">Gaming Apps</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
        <div className="project-item">
          <div className="project-img">
            <img
              src="img/Project/webimg.jpg"
              className="img-fluid w-100 rounded"
              alt=""
            />
            <div className="project-content">
              <a href="#" className="text-center">
                <h4 className="text-secondary">Web Development</h4>
                <p className="m-0 text-white">Web Analysis</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
        <div className="project-item">
          <div className="project-img">
            <img
              src="img/Project/digital.jpg"
              className="img-fluid w-100 rounded"
              alt=""
            />
            <div className="project-content">
              <a href="#" className="text-center">
                <h4 className="text-secondary">Digital Marketing</h4>
                <p className="m-0 text-white">Marketing Analysis</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
        <div className="project-item">
          <div className="project-img">
            <img
              src="img/Project/web3.jpg"
              className="img-fluid w-100 rounded"
              alt=""
            />
            <div className="project-content">
              <a href="#" className="text-center">
                <h4 className="text-secondary">Web3 & Block Chain</h4>
                <p className="m-0 text-white">Web3 & Block Chain</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    );
}


































