import React from "react";

export default function Aboutus() {
    return (
        <>
            <div className="container-fluid py-5 my-5">
    <div className="container pt-5">
      <div className="row g-5">
        <div
          className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
          data-wow-delay=".3s"
        >
          <div className="h-100 position-relative">
            <img
              src="img/about-1.jpg"
              className="img-fluid w-75 rounded"
              alt=""
              style={{ marginBottom: "25%" }}
            />
            <div
              className="position-absolute w-75"
              style={{ top: "25%", left: "25%" }}
            >
              <img
                src="img/about-2.jpg"
                className="img-fluid w-100 rounded"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
          data-wow-delay=".5s"
        >
          <h5 className="text-primary">About Us</h5>
          <h1 className="mb-4">
            About IntrologyTech Agency And It's Innovative IT Solutions
          </h1>
          <p>
          Intrilogy is a <a href="">top mobile app development company</a> for start-ups and enterprises
						headquartered in Noida, India, and have sales offices in the Delhi, Patna, Lucknow And
						Bengaluru. With 6+ years of experience in Software Development, Intrilogy has developed
						200+ Apps with best quality, technologies and ideas. We always choose quality over quantity and
						as a reliable app development company, we prefer modern technologies..
          </p>
          <p className="mb-4">
          The company explore its foot print and work on the emerging technologies like Chabot, AI, IoT,
						wearable Blockchain, AR/VR, and many more. Intrilogy has specialization in Mobile App
						Development, Android app development, iPhone App Development, UI/UX designs, Web and CMS App
						Development, Blockchain Technology, CRM Development and many more. The company offers
						customized app as per the clients' requirements at cost-effective prices and within a given
						timeframe. The company has also been supporting its clients in other processes like quality
						assurance testing, application testing, and in the development of real-time solutions, chat bots
						etc.
          </p>
          <a
            href=""
            className="btn btn-secondary rounded-pill px-5 py-3 text-white"
          >
            More Details
          </a>
        </div>
      </div>
    </div>
  </div>
  
  
        </>
    );
}
