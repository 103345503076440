

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AvBar from './Components/AvBar';
import Footer from './Components/footer';
import Home from './Components/home';
import Aboutus from './Components/aboutus';
 import Services from './Components/services';
 import Projects  from './Components/projects';
// import Projects from './pages/Projects';
// import Blog from './pages/Blog';
// import Team from './pages/Team';
// import Testimonial from './pages/Testimonial';
// import NotFound from './pages/NotFound';
import Contact from './Components/contact';

function App() {
  return (
    <Router>
      <AvBar />
      {/* <Projects/> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        {/* <Route path="/blog" element={<Blog />} /> */}
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;

